import React, { useState, useEffect } from 'react';
import API_KEY from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCode, faRectangleList, faHandPointRight} from '@fortawesome/free-solid-svg-icons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import BookmarkButton from './common/BookmarkButton';
import AdSense from './AdSense';
import '../styles/InterviewQuestions.css';

const InterviewQuestions = ({ topic }) => {
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const { addToHistory } = useAuth();

    // Add history tracking when component mounts
    useEffect(() => {
        if (topic) {
            addToHistory('topic', {
                id: topic,
                title: `${topic} Interview Questions`
            });
        }
    }, [topic, addToHistory]);

    // Fetch questions based on the topic and active tab
    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/interviewPreparationGuides/${encodeURIComponent(topic)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${API_KEY}`
                    }
                });
                const data = await response.json();
                setQuestions(data.interview_preparation_guide.questions_and_answers);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };
        fetchQuestions();
    }, [topic]);

    const handleQuestionClick = (questionIndex) => {
      setSelectedQuestion(selectedQuestion === questionIndex ? null : questionIndex);
      
      // Scroll the question into view
      setTimeout(() => {
          const questionElement = document.getElementById(`question-${questionIndex}`);
          if (questionElement) {
            const offset = 100; // Adjust this value to match the height of your sticky tabs
            const elementPosition = questionElement.getBoundingClientRect().top - 40 + window.scrollY;
            window.scrollTo({
                top: elementPosition - offset,
                behavior: 'smooth'
            });
          }
      }, 100); 
  };

    const handleTabSelect = (index) => {
        setActiveTab(index);
        sessionStorage.setItem(`activeTab_${topic}`, index);
        setSelectedQuestion(null);

        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    };

    const restoreCodeExample = (data) => {
      if (typeof data === 'string') {
        return JSON.parse(data.replace('*****', ''))
      }

      return data
    }

    return (
        <div className="interview-questions-container">
            <div className="content-column">
                <div className="interview-questions">
                    {/* <p>Questions and answers tailored to help you prepare for your next big opportunity.</p> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        
                        <h2 style={{ margin: 0 }}>{topic} Interview Questions</h2>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                            <BookmarkButton 
                                type="interview"
                                item={{
                                    id: topic,
                                    title: `${topic} Interview Questions`
                                }}
                            />
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link to={`/topics/${encodeURIComponent(topic)}`} style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faHandPointRight} size="xl" color="red" />
                            <span style={{ marginLeft: '10px', marginBottom: '0' }}>Check the cheat sheet</span>
                        </Link>
                    </div>

                
                    <Tabs selectedIndex={activeTab} onSelect={handleTabSelect}>
                        <TabList className="sticky-tabs">
                            <Tab>Beginner</Tab>
                            <Tab>Intermediate</Tab>
                            <Tab>Advanced</Tab>
                        </TabList>

                        <div className="tab-panels-container">
                            {/* Tab Panels */}
                            <TabPanel>
                                <>
                                    <div className="questions" style={{backgroundColor: '#FFF2C4',
                                        padding: '10px',
                                        paddingTop: '10px',
                                        margin: '0px',
                                        borderRadius: '5px',
                                        whiteSpace: 'pre-wrap',
                                        border: '1px solid gray'}}>
                                        {questions.filter((q) => q.level === 'Beginner').map((q, index) => (
                                            <>
                                                <div key={index} id={`question-${index}`} className="question-container" style={selectedQuestion === index ? { border: '3px solid #ccc', backgroundColor: '#ddd', boxShadow: '0 2x 3px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24)', borderStyle: 'solid'} : {}}>
                                                    <div className="question" onClick={() => handleQuestionClick(index)}>
                                                        {index + 1} - {q.question}
                                                        <FontAwesomeIcon style={{marginLeft: '10px'}} icon={selectedQuestion === index ? faChevronUp : faChevronDown} />
                                                    </div>
                                                    {selectedQuestion === index && (
                                                        <div className="answer">
                                                            <hr></hr>
                                                            <div>
                                                                <p><strong>Answer:</strong> {q.answer.explanation}</p>
                                                                <p><strong>Supporting Information:</strong> {q.answer.supporting_information}</p>
                                                            </div>
                                                            {q.answer.code_example && (
                                                                <pre className="code-example"  style={{ backgroundColor: '#444', color: '#eee'}}>>
                                                                {q.answer.code_example.replace('*****', '')}
                                                                </pre>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <hr></hr>
                                            </>
                                        ))}
                                    </div>
                                    <div style={{ width: '100%', minWidth: '300px', margin: '20px 0' }}>
                                        <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                                        <AdSense adClient="ca-pub-6060537356298204" adSlot="2618836852" adFormat="fluid" layoutKey="-fb+5w+4e-db+86" />
                                    </div>
                                </>
                            </TabPanel>
                            <TabPanel>
                                <>
                                    <div className="questions" style={{ backgroundColor: '#FFF2C4',
                                        padding: '10px',
                                        paddingTop: '10px',
                                        borderRadius: '5px',
                                        whiteSpace: 'pre-wrap',
                                        border: '1px solid gray',
                                        color: '#000'}}>
                                        {questions.filter((q) => q.level === 'Intermediate').map((q, index) => (
                                            <>
                                                <div key={index} id={`question-${index}`} className="question-container" style={selectedQuestion === index ? { border: '3px solid #ccc', backgroundColor: '#eee', boxShadow: '0 3px 3px rgba(0,0,0,0.92), 0 3px 2px rgba(0,0,0,0.94)'} : {}}>
                                                    <div className="question" onClick={() => handleQuestionClick(index)}>
                                                        {index + 1} - {q.question}
                                                        <FontAwesomeIcon style={{marginLeft: '10px'}} icon={selectedQuestion === index ? faChevronUp : faChevronDown} />
                                                    </div>
                                                    {selectedQuestion === index && (
                                                        <div className="answer">
                                                            <hr></hr>
                                                            <div style={{ marginTop: '10px'}}>
                                                                <p><strong>Answer:</strong> {q.answer.explanation}</p>
                                                                <p><strong>Supporting Information:</strong> {q.answer.supporting_information}</p>
                                                            </div>
                                                            {q.answer.code_example && (
                                                                <pre className="code-example" style={{ backgroundColor: '#444', color: '#eee'}}>>
                                                                {q.answer.code_example.replace('*****', '')}
                                                                </pre>
                                                            )}
                                                        </div>
                                                        
                                                    )}
                                                </div>
                                                <hr></hr>
                                            </>
                                        ))}
                                    </div>
                                    <div style={{ width: '100%', minWidth: '300px', margin: '20px 0' }}>
                                        <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                                        <AdSense adClient="ca-pub-6060537356298204" adSlot="2618836852" adFormat="fluid" layoutKey="-fb+5w+4e-db+86" />
                                    </div>
                                </>
                            </TabPanel>
                            <TabPanel>
                                <>
                                <div className="questions" style={{ backgroundColor: '#FFF2C4',
                                    padding: '10px',
                                    paddingTop: '10px',
                                    borderRadius: '5px',
                                    whiteSpace: 'pre-wrap',
                                    border: '1px solid gray',
                                    color: '#000'}}>
                                    {questions.filter((q) => q.level === 'Advanced').map((q, index) => (
                                        <>
                                            <div key={index} id={`question-${index}`} className="question-container" style={selectedQuestion === index ? { border: '3px solid #ccc', backgroundColor: '#eee', boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'} : {}}>
                                                <div className="question" onClick={() => handleQuestionClick(index)}>
                                                    {index + 1} - {q.question}
                                                    <FontAwesomeIcon style={{marginLeft: '10px'}} icon={selectedQuestion === index ? faChevronUp : faChevronDown} />
                                                </div>
                                                {selectedQuestion === index && (
                                                    <div className="answer">
                                                        <hr></hr>
                                                        <div>
                                                            <p><strong>Answer:</strong> {q.answer.explanation}</p>
                                                            <p><strong>Supporting Information:</strong> {q.answer.supporting_information}</p>
                                                        </div>
                                                        {q.answer.code_example && (
                                                            <pre className="code-example" style={{ backgroundColor: '#444', color: '#eee'}}>
                                                            {q.answer.code_example.replace('*****', '')}
                                                            </pre>
                                                        )}
                                                    </div>
                                                )}
                                                
                                            </div>
                                            <hr></hr>
                                        </>
                                    ))}
                                </div>
                                <div style={{ width: '100%', minWidth: '300px', margin: '20px 0' }}>
                                    <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                                    <AdSense adClient="ca-pub-6060537356298204" adSlot="2618836852" adFormat="fluid" layoutKey="-fb+5w+4e-db+86" />
                                </div>
                                </>
                            </TabPanel>
                        </div>
                    </Tabs>
                </div>
            </div>
            <div className="ad-column"
                style={{
                    width: '100%',
                    minWidth: '300px',
                    margin: '20px 0',
                    display: 'block', 
                }}
                >
                <span style={{ fontSize: '10px', color: 'grey' }}>advertisement</span>
                <AdSense
                    adClient="ca-pub-6060537356298204"
                    adSlot="1742545866"
                    adFormat="autorelaxed"
                    style={{ display: 'block', width: '100%' }} 
                />
            </div>
        </div>
    );
};

export default InterviewQuestions;
