import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUser, 
  faEnvelope, 
  faHistory,
  faGraduationCap,
  faClock,
  faBookmark,
  faTrash,
  faQuestionCircle,
  faRectangleList
} from '@fortawesome/free-solid-svg-icons';
import '../../styles/UserProfile.css';

const UserProfile = () => {
  const { currentUser, getUserProfile, toggleBookmark } = useAuth();
  const [userProfile, setUserProfile] = useState(null);
  const [activeTab, setActiveTab] = useState('recent');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await getUserProfile();
      setUserProfile(profile);
    };
    fetchProfile();
  }, [getUserProfile]);

  const handleRemoveBookmark = async (type, item) => {
    setLoading(true);
    try {
      await toggleBookmark(type, item);
      // Refresh profile to get updated bookmarks
      const profile = await getUserProfile();
      setUserProfile(profile);
    } catch (error) {
      console.error('Error removing bookmark:', error);
    }
    setLoading(false);
  };

  if (!userProfile) {
    return (
      <div className="loading-spinner">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    // Handle Firestore timestamp
    if (timestamp.seconds) {
      return new Date(timestamp.seconds * 1000).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }
    
    // Handle ISO string or regular date string
    try {
      return new Date(timestamp).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'N/A';
    }
  };

  const getUrlPath = (type, itemId) => {
    switch(type) {
      case 'interview':
        return `/interview-questions/${itemId}`;
      default:
        return `/${type}s/${itemId}`;
    }
  };

  const renderHistoryList = (items) => {
    if (!items || items.length === 0) {
      return <p className="text-muted">No items viewed yet</p>;
    }

    return (
      <div className="history-list">
        {items.map((item, index) => (
          <Link 
            key={`${item.id}-${index}`}
            to={`/${item.type}s/${item.id}`}
            className="history-item"
          >
            <div className="history-item-content">
              <h4 style={{ fontSize: 'smaller'}}>{item.title}</h4>
              <div className="history-item-meta">
                <span className="item-type">
                  <FontAwesomeIcon 
                    icon={item.type === 'topic' ? faRectangleList : faGraduationCap} 
                  />
                  {item.type}
                </span>
                <span className="item-date">
                  <FontAwesomeIcon icon={faClock} />
                  {formatDate(item.timestamp)}
                </span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    );
  };

  const renderBookmarksList = (items, type) => {
    if (!items || items.length === 0) {
      return <p className="text-muted">No bookmarks yet</p>;
    }

    return (
      <div className="history-list">
        {items.map((item, index) => (
          <div key={`${item.id}-${index}`} className="bookmark-item">
            <Link 
              to={getUrlPath(type, item.id)}
              className="bookmark-link"
            >
              <div className="bookmark-content">
                <h4 style={{ fontSize: 'smaller'}}>{item.title}</h4>
                <div className="bookmark-meta">
                  <span className="item-type">
                    <FontAwesomeIcon 
                      icon={type === 'topic' ? faRectangleList : type === 'tutorial' ? faGraduationCap : faQuestionCircle} 
                    />
                    {type}
                  </span>
                  <span className="item-date">
                    <FontAwesomeIcon icon={faClock} />
                    {formatDate(item.addedAt)}
                  </span>
                </div>
              </div>
            </Link>
            <button
              className="remove-bookmark-btn"
              onClick={() => handleRemoveBookmark(type, item)}
              disabled={loading}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="profile-avatar">
          {userProfile.photoURL ? (
            <img src={userProfile.photoURL} alt="Profile" />
          ) : (
            <FontAwesomeIcon icon={faUser} size="2x" />
          )}
        </div>
        <div className="profile-info">
          <h2>{userProfile.displayName}</h2>
          <p>
            <FontAwesomeIcon icon={faEnvelope} />
            {userProfile.email}
          </p>
          <p className="text-muted">
            Member since {formatDate(userProfile.createdAt)}
          </p>
        </div>
      </div>

      <div className="profile-content">
      <div className="profile-stats">
          {/* <div className="stat-item">
            <span className="stat-value">{userProfile.loginCount}</span>
            <span className="stat-label">Logins</span>
          </div> */}
          <div className="stat-item">
            <span className="stat-value">{userProfile.history?.topics?.length || 0}</span>
            <span className="stat-label">Topics</span>
          </div>
          <div className="stat-item">
            <span className="stat-value">{userProfile.history?.tutorials?.length || 0}</span>
            <span className="stat-label">Tutorials</span>
          </div>
          <div className="stat-item">
            <span className="stat-value">
              {(userProfile.bookmarks?.topics?.length || 0) + 
                (userProfile.bookmarks?.tutorials?.length || 0) +
                (userProfile.bookmarks?.interviews?.length || 0)}
            </span>
            <span className="stat-label">Bookmarks</span>
          </div>
        </div>
        <div className="profile-tabs">
          <button
            className={`tab-button ${activeTab === 'recent' ? 'active' : ''}`}
            onClick={() => setActiveTab('recent')}
          >
            <FontAwesomeIcon icon={faHistory} />
            Recently Viewed
          </button>
          <button
            className={`tab-button ${activeTab === 'topics' ? 'active' : ''}`}
            onClick={() => setActiveTab('topics')}
          >
            <FontAwesomeIcon icon={faRectangleList} />
            Topics
          </button>
          <button
            className={`tab-button ${activeTab === 'tutorials' ? 'active' : ''}`}
            onClick={() => setActiveTab('tutorials')}
          >
            <FontAwesomeIcon icon={faGraduationCap} />
            Tutorials
          </button>
          <button
            className={`tab-button ${activeTab === 'bookmarks' ? 'active' : ''}`}
            onClick={() => setActiveTab('bookmarks')}
          >
            <FontAwesomeIcon icon={faBookmark} />
            Bookmarks
          </button>
        </div>

        <div className="tab-content">
          {activeTab === 'recent' && (
            <div className="history-section">
              <h4>Recently Viewed</h4>
              {renderHistoryList(userProfile.history?.lastViewed)}
            </div>
          )}
          {activeTab === 'topics' && (
            <div className="history-section">
              <h4>Topics History</h4>
              {renderHistoryList(userProfile.history?.topics)}
            </div>
          )}
          {activeTab === 'tutorials' && (
            <div className="history-section">
              <h4>Tutorials History</h4>
              {renderHistoryList(userProfile.history?.tutorials)}
            </div>
          )}
          {activeTab === 'bookmarks' && (
            <div className="bookmarks-section">
              <div className="bookmark-category">
                <h4>Bookmarked Topics</h4>
                {renderBookmarksList(userProfile.bookmarks?.topics, 'topic')}
              </div>
              <div className="bookmark-category">
                <h4>Bookmarked Tutorials</h4>
                {renderBookmarksList(userProfile.bookmarks?.tutorials, 'subtopicTutorial')}
              </div>
              <div className="bookmark-category">
                <h4>Bookmarked Interview Questions</h4>
                {renderBookmarksList(userProfile.bookmarks?.interviews, 'interview')}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
