import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/common/ProtectedRoute';
import Navigation from './Navigation';
import Home from './components/pages/Home';
import Topics from './components/Topics';
import TopicDetails from './components/TopicDetails';
import TopicHistory from './components/TopicHistory';
import SubtopicTutorial from './components/SubtopicTutorial';
import About from './components/pages/About';
import TermsOfService from './components/pages/TermsOfService';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import Login from './components/pages/Login';
import Footer from './components/Footer';
import ReactGA from 'react-ga4';
import UserProfile from './components/pages/UserProfile';
import InterviewQuestionsPage from './components/pages/InterviewQuestionsPage';

import './App.css';

const AppContent = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navigation style={{ position: 'sticky', top: 0, zIndex: 1000 }} />
      <div style={{ flex: 1, paddingTop: '0px' }}>
        <div className="main-content">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/login" element={<Login />} />

            {/* Protected Routes */}
            <Route
              path="/topics"
              element={
                <Topics />
              }
            />
            <Route
              path="/topics/:topic"
              element={
                <TopicDetails />
              }
            />
            <Route
              path="/topicHistories/:topic"
              element={
                <TopicHistory />
              }
            />
            <Route
              path="/subtopicTutorials/:topic_subtopic"
              element={
                <SubtopicTutorial />
              }
            />
            <Route
              path="/interview-questions/:topic"
              element={
                // <ProtectedRoute>
                <InterviewQuestionsPage />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <UserProfile />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;
