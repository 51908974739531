import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck, faGraduationCap, faHistory, faPrint, faQuestionCircle, faGift } from '@fortawesome/free-solid-svg-icons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useAuth } from '../contexts/AuthContext';
import BookmarkButton from './common/BookmarkButton';

import Books from './Books'
import ExternalResources from './ExternalResources'

import { Helmet } from 'react-helmet';
import API_KEY from '../config';
import AdSense from './AdSense';

import { styles } from '../styles/topicDetailsStyle';
import 'react-tabs/style/react-tabs.css';
import 'react-tooltip/dist/react-tooltip.css';
import '../styles/topicDetailsStyle';
import '../styles/Tabs.css';
import RelatedTopics from './RelatedTopics';

const TopicDetails = () => {
    const { topic } = useParams();
    const { addToHistory } = useAuth();
    const [topicName, setTopicName] = useState("");
    const [beginnerTopics, setBeginnerTopics] = useState([]);
    const [intermediateTopics, setIntermediateTopics] = useState([]);
    const [advancedTopics, setAdvancedTopics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [activeTabIndex2, setActiveTabIndex2] = useState(0);
    const [isLargeScreen, setIsLargeScreen] = useState(true);

    useEffect(() => {       
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 1024);
        };

        // Set the initial screen size
        handleResize();

        // Add event listener for resizing
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {  
        // Retrieve the active tab index from sessionStorage if available
        const storedTabIndex = sessionStorage.getItem(`activeTabIndex_${topic}`);
        if (storedTabIndex !== null) {
            setActiveTabIndex(Number(storedTabIndex));
        }

        getCheatsheetData(topic);
    }, [topic]);

    useEffect(() => {
        if (topicName) {
            // Track topic view in user's history
            addToHistory('topic', {
                id: topic,
                title: topicName
            });
        }
    }, [topicName, topic, addToHistory]);

    const getCheatsheetData = async (topic) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/subtopics/${encodeURIComponent(topic)}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${API_KEY}`
                }
            });
              
            const data = await response.json();
            setTopicName(topic);
            setBeginnerTopics(await data.filter(topic => topic.level === "beginner"));
            setIntermediateTopics(await data.filter(topic => topic.level === "intermediate"));
            setAdvancedTopics(await data.filter(topic => topic.level === "advanced"));
        } catch (error) {
            console.error('Error fetching subtopics:', error);
        } finally {
            setLoading(false);
        }        
    }

    const handleTabSelect = (index) => {
        setActiveTabIndex(index);
        sessionStorage.setItem(`activeTabIndex_${topic}`, index);
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const handleTabSelect2 = (index) => {
        setActiveTabIndex2(index);
        if (index === 1) setActiveTabIndex(3);
        sessionStorage.setItem(`activeTabIndex_${topic}`, 3);
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div style={styles.panel}>
            <Helmet>
                <title>Cheatsheet++ for {topic} | Cheatsheet++</title>
                <meta
                    name="description"
                    content={`Master ${topic} with our detailed cheat sheet, including beginner, intermediate, and advanced guides. Access specific tutorials and learn the history of ${topicName} with our interactive pop-ups on Cheatsheet++.`}
                />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6060537356298204"
                    crossOrigin="anonymous"></script>
            </Helmet>

            {loading ? (
                <div>Loading...</div>
            ) : (
                <div style={styles.container}>
                    {/* <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                    <AdSense adClient="ca-pub-6060537356298204" adSlot="2994241377" adFormat="fluid" layoutKey='-fb+5w+4e-db+86'/> */}
                
                    <header style={styles.header}>
                        <div style={styles.headerContent}>
                            <div style={styles.headerTitleContainer}>
                                <h3 style={styles.headerTitle}>
                                    {topicName.toUpperCase()}
                                </h3>
                            </div>
                            {/* <div style={{alignContent: 'center'}}>
                                <Link to={`/interview-questions/${encodeURIComponent(topicName)}`}>
                                    <FontAwesomeIcon icon={faQuestionCircle} size="xl" color="red" style={styles.historyIcon}/>
                                    <span>Q&A</span>
                                </Link>
                            </div> */}
                            <div style={{alignContent: 'center'}}>
                                <Link to={`/topicHistories/${encodeURIComponent(topicName)}`}>
                                    <FontAwesomeIcon icon={faHistory} size="lg" color="#003860" style={styles.historyIcon}/>
                                    <span>Backstory</span>
                                </Link>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <BookmarkButton
                                    type="topic"
                                    item={{
                                        id: topic,
                                        title: topicName
                                    }}
                                />
                            </div>
                        </div>
                    </header>

                    <RelatedTopics topic={topic}/>
                    <div style={{alignContent: 'center', marginBottom: '10px'}}>
                        <Link to={`/interview-questions/${encodeURIComponent(topicName)}`}>
                            <FontAwesomeIcon icon={faGift} size="xl" color="red" style={styles.historyIcon}/>
                            <span>Also see our Interview Q&A growing library</span>
                        </Link>
                    </div>
                    {/* <h1 style={{textAlign: 'center', color: '#666'}}>cheat sheet</h1> */}
                    <div style={styles.main}>
                        {isLargeScreen ? (
                            <>
                                {/* <Tabs selectedIndex={activeTabIndex} onSelect={handleTabSelect2}>
                                    <TabList className="sticky-tabs1">
                                        <Tab>Cheat Sheet</Tab>
                                        <Tab>Resources</Tab>
                                    </TabList>

                                    <div className="tab-panels-container">
                                        <TabPanel>
                                            
                                        </TabPanel>
                                        <TabPanel>
                                            <Books topic={topic} />
                                            <ExternalResources topic={topic}/>
                                        </TabPanel>
                                    </div>
                                </Tabs> */}
                                <div style={styles.columns}>
                                    <div style={styles.column}>
                                        <Section topic={topic} title="Beginner" codeBlockStyle={styles.codeBlockBeginner}>
                                            <Subtopics topic={topic} subtopics={beginnerTopics} style={{ fontSize: 'larger' }}/>
                                        </Section>
                                    </div>
                                    <div style={styles.column}>
                                        <Section topic={topic} title="Intermediate" codeBlockStyle={styles.codeBlockIntermediate}>
                                            <Subtopics topic={topic} subtopics={intermediateTopics} style={{ fontSize: 'larger' }}/>
                                        </Section>
                                    </div>
                                    <div style={styles.column}>
                                        <Section topic={topic} title="Advanced" codeBlockStyle={styles.codeBlockAdvanced}>
                                            <Subtopics topic={topic} subtopics={advancedTopics} style={{ fontSize: 'larger' }}/>
                                        </Section>
                                    </div>
                                </div>
                            </>

                        ) : (
                            <>
                                <Tabs selectedIndex={activeTabIndex} onSelect={handleTabSelect}>
                                    <TabList className="sticky-tabs">
                                        <Tab>Beginner</Tab>
                                        <Tab>Intermediate</Tab>
                                        <Tab>Advanced</Tab>
                                        {/* <Tab>Resources</Tab> */}
                                    </TabList>

                                    <div className="tab-panels-container">
                                        <TabPanel>
                                            <Section topic={topic} title="Beginner" codeBlockStyle={styles.codeBlockBeginner}>
                                                <Subtopics topic={topic} title="Beginner" subtopics={beginnerTopics} />
                                            </Section>
                                        </TabPanel>
                                        <TabPanel>
                                            <Section topic={topic} title="Intermediate" codeBlockStyle={styles.codeBlockIntermediate}>
                                                <Subtopics topic={topic} title="Intermediate" subtopics={intermediateTopics} />
                                            </Section>
                                        </TabPanel>
                                        <TabPanel>
                                            <Section topic={topic} title="Advanced" codeBlockStyle={styles.codeBlockAdvanced}>
                                                <Subtopics topic={topic} title="Advanced" subtopics={advancedTopics} />
                                            </Section>
                                        </TabPanel>
                                    {/* <TabPanel>
                                        <Books topic={topic} />
                                        <ExternalResources topic={topic}/>
                                    </TabPanel> */}
                                </div>
                            </Tabs>
                            </>

                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const printSection = (sectionId) => {
    const sectionContent = document.getElementById(sectionId).innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = sectionContent;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload()
};

const Section = ({ topic, title, children, codeBlockStyle }) => (
    <>
        <div style={styles.section} id={`section-${title.toLowerCase()}`}>        
            <h2 style={styles.sectionTitle}>
                {title}
                <button onClick={() => printSection(`section-${title.toLowerCase()}`)} style={styles.printButton}>
                    <FontAwesomeIcon icon={faPrint} size="1x" style={{ marginRight: '5px' }} />
                    Print
                </button>
            </h2>
            {/* <Books topic={topic} index={title === 'Beginner' ? 0 : (title === 'Intermediate' ? 1 : 2)}/> */}
            {React.Children.map(children, child =>
                React.cloneElement(child, { codeBlockStyle })
            )}
        </div>

        <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
        <AdSense adClient="ca-pub-6060537356298204" adSlot="7104543639" adFormat="auto" />
    </>

);

const Subtopics = ({ topic, title, subtopics, codeBlockStyle }) => {
    const [copiedIndex, setCopiedIndex] = useState(null);

    const copyToClipboard = (code, index) => {
        navigator.clipboard.writeText(code).then(() => {
            setCopiedIndex(index);
            setTimeout(() => {
                setCopiedIndex(null);
            }, 2000);
        }).catch((err) => {
            console.error('Could not copy text: ', err);
        });
    };

    return (
        <>
            <ul>
                {subtopics.map((subtopic, index) => (
                    <li key={index} style={styles.listItem}>
                        {/* <div>
                            <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                            <AdSense adClient="ca-pub-6060537356298204" adSlot="2994241377" adFormat="fluid" layoutKey='-fb+5w+4e-db+86'/>
                        </div> */}

                        { index === (subtopics.length / 2) &&
                            <>
                                <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                                {
                                    title === 'Beginner' &&
                                    <AdSense adClient="ca-pub-6060537356298204" adSlot="1282101231" adFormat="fluid" adLayout="in-article" />
                                }
                                {
                                    title === 'Intermediate' &&
                                    <AdSense adClient="ca-pub-6060537356298204" adSlot="4315458684" adFormat="fluid" adLayout="in-article" />
                                }
                                {
                                    title === 'Advanced' &&
                                    <AdSense adClient="ca-pub-6060537356298204" adSlot="9692704608" adFormat="fluid" adLayout="in-article" />
                                }
                                <br />
                            </>
                        }

                        <h3 style={styles.subtopicTitle}>
                            {subtopic.key} 
                            <Link to={`/subtopicTutorials/${encodeURIComponent(`${topic}_${subtopic.key}`)}`}>
                                <FontAwesomeIcon icon={faGraduationCap} size="1x" style={{ cursor: 'pointer', marginLeft: '10px' }}/><span style={{ fontSize: 'smaller', marginLeft: '5px'}}>Tutorial</span>
                            </Link>
                        </h3>
                        <p style={{marginTop: '7px'}}>{subtopic.description}</p>
                        <button 
                            onClick={() => copyToClipboard(
                                subtopic.examples.map((example, exIndex) => (
                                    example.code
                                )), index)} 
                            style={{ textAlign: 'right', border: 'none', background: 'none', cursor: 'pointer' }}>
                            <FontAwesomeIcon 
                                icon={copiedIndex === index ? faCheck : faCopy} 
                                size="1x" 
                            /> 
                            {copiedIndex === index ? ' Copied' : ' Copy'}
                        </button>
                        
                        <pre style={codeBlockStyle}>
                            {subtopic.examples.map((example, exIndex) => (
                                <code key={exIndex} style={{marginBottom: '10px'}}>
                                    <br /><b>{example.name}</b><br />
                                    {example.code}<br />
                                </code>
                            ))}
                        </pre>
                        <br></br>
                    </li>
                ))}
            </ul>
            <br />       
        </>

    );
};

export default TopicDetails;
