import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, Col, Row, Button, FormControl, InputGroup, Overlay, Popover, Form, ButtonGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import API_KEY from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowLeft, faArrowRight, faSpinner, faCog, faQuestionCircle, faRectangleList } from '@fortawesome/free-solid-svg-icons';
import '../styles/topicsStyle.css';
import '../styles/TopicsCard.css';
import debounce from 'lodash/debounce';
import ReactCardFlip from "react-card-flip";

const BASE_URL = process.env.REACT_APP_BACKEND_SERVER_URL;
const ITEMS_PER_PAGE = 24;

const Topics = () => {
    const [topics, setTopics] = useState([]);
    const [topicsData, setTopicsData] = useState({ topics: [] });
    const [interviewData, setInterviewData] = useState({ topics: [] });
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [expanded, setExpanded] = useState({});
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        totalTopics: 0,
        hasNextPage: false,
        hasPrevPage: false
    });
    const [paginationText, setPaginationText] = useState('');
    const [showSearchOptions, setShowSearchOptions] = useState(false);
    const [searchOptions, setSearchOptions] = useState({
        searchInTitle: true,
        searchInDesc: true
    });
    const [viewMode, setViewMode] = useState('cheatsheets');
    const [error, setError] = useState(null);
    
    const searchOptionsRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    // Function to fetch both topics and interview data
    const fetchAllData = async (page = 1, searchTerm = '', options = searchOptions) => {
        setLoading(true);
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${API_KEY}`
            };

            const searchParams = new URLSearchParams({
                page: page.toString(),
                limit: ITEMS_PER_PAGE.toString(),
                search: searchTerm,
                searchInTitle: options.searchInTitle.toString(),
                searchInDesc: options.searchInDesc.toString()
            });

            // Fetch both datasets in parallel
            const [topicsResponse, interviewResponse] = await Promise.all([
                fetch(`${BASE_URL}/api/topics?mode=cheatsheets&${searchParams}`, {
                    method: 'GET',
                    headers
                }),
                fetch(`${BASE_URL}/api/topics?mode=interview&${searchParams}`, {
                    method: 'GET',
                    headers
                })
            ]);

            if (!topicsResponse.ok || !interviewResponse.ok) {
                throw new Error('Failed to fetch data');
            }

            const topicsResult = await topicsResponse.json();
            const interviewResult = await interviewResponse.json();

            // Create a map of interview questions by topic
            const interviewMap = new Map(
                interviewResult.topics.map(topic => [topic.topic, topic])
            );

            // Create aligned interview data based on topics
            const alignedInterviewData = {
                topics: topicsResult.topics.map(topic => ({
                    ...interviewMap.get(topic.topic) || {
                        topic: topic.topic,
                        beginnerCount: 0,
                        intermediateCount: 0,
                        advancedCount: 0,
                    },
                    _id: topic._id // Ensure we use the same _id as the topic
                })),
                pagination: topicsResult.pagination // Use the same pagination as topics
            };

            setTopicsData({
                topics: topicsResult.topics,
                pagination: topicsResult.pagination
            });
            
            setInterviewData(alignedInterviewData);
            
            if (viewMode === 'cheatsheets') {
                setTopics(topicsResult.topics);
            } else {
                setTopics(alignedInterviewData.topics);
            }

            setPagination({
                currentPage: parseInt(page),
                totalPages: topicsResult.pagination?.totalPages || 1,
                totalTopics: topicsResult.pagination?.totalTopics || 0,
                hasNextPage: topicsResult.pagination?.hasNextPage || false,
                hasPrevPage: topicsResult.pagination?.hasPrevPage || false
            });

            setPaginationText(`Page ${parseInt(page)} of ${topicsResult.pagination.totalPages} 
                                (${topicsResult.pagination.totalTopics} total topics)`);
            
            setError(null);
        } catch (err) {
            setError('Failed to load topics. Please try again later.');
            // console.error('Error fetching data:', err);
        }
        setLoading(false);
    };

    // Handle URL parameters and initial fetch
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const query = params.get('search') || '';
        const page = parseInt(params.get('page')) || 1;
        const searchInTitle = params.get('searchInTitle') !== 'false';
        const searchInDesc = params.get('searchInDesc') !== 'false';
        
        setSearchTerm(query);
        setSearchOptions({ searchInTitle, searchInDesc });
        
        fetchAllData(page, query, { searchInTitle, searchInDesc });

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, [location.search]);

    const handleSearch = useCallback(
        debounce((term, options) => {
            const params = new URLSearchParams();
            if (term) {
                params.set('search', term);
            }
            params.set('page', '1');
            params.set('searchInTitle', options.searchInTitle.toString());
            params.set('searchInDesc', options.searchInDesc.toString());
            navigate({ search: params.toString() });
        }, 300),
        [navigate]
    );

    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        handleSearch(newSearchTerm, searchOptions);
    };

    const handleSearchOptionChange = (option) => {
        const newOptions = {
            ...searchOptions,
            [option]: !searchOptions[option]
        };

        // Prevent both options from being unchecked
        if (!newOptions.searchInTitle && !newOptions.searchInDesc) {
            return;
        }

        setSearchOptions(newOptions);
        handleSearch(searchTerm, newOptions);
    };

    const handleViewModeChange = (mode) => {
        if (mode !== viewMode) {
            setViewMode(mode);
            const currentData = mode === 'cheatsheets' ? topicsData : interviewData;

            setExpanded({});
            setFlip(!flip);
            setTopics(currentData.topics);            

            // console.log('currentData', currentData || {});
            // console.log('pagination.currentPage', pagination.currentPage)
            // console.log('pagination.totalPages', pagination.totalPages)
            
            setPagination({
                currentPage: pagination.currentPage,
                totalPages: pagination.totalPages,
                totalTopics: (currentData.topics || []).length,
                hasNextPage: pagination.currentPage < pagination.totalPages,
                hasPrevPage: pagination.currentPage > 1
            });
        }
    };

    const handlePageChange = (newPage) => {
        const params = new URLSearchParams(location.search);
        const currentPage = parseInt(newPage);
        
        if (searchTerm) {
            params.set('search', searchTerm);
        }
        params.set('page', currentPage.toString());
        params.set('searchInTitle', searchOptions.searchInTitle.toString());
        params.set('searchInDesc', searchOptions.searchInDesc.toString());
        navigate({ search: params.toString() });
    };

    const [flip, setFlip] = useState(false);

    return (
        <div className="container" style={{marginTop: '0px'}}>
            <Helmet>
                <title>{`Cheatsheet++ - ${searchTerm ? `${searchTerm} Topics` : viewMode === 'cheatsheets' ? 'Topics' : 'Interview Questions'}`}</title>
                <meta
                    name="description"
                    content={`Discover comprehensive ${viewMode === 'cheatsheets' ? 'cheat sheets' : 'interview questions'} ${searchTerm ? `related to ${searchTerm}` : ''} across a wide range of topics. Enhance your learning with beginner, intermediate, and advanced guides on Cheatsheet++.`}
                />
            </Helmet>
            
            <div style={{ 
                position: 'sticky',
                top: '70px',
                backgroundColor: 'white',
                zIndex: 900,
                paddingTop: '10px',
                paddingBottom: '10px',
                // borderBottom: '1px solid #dee2e6'
            }}>
                <div style={{ textAlign: 'center' }}>
                    <ButtonGroup className="mb-3">
                        <Button
                            style={{ backgroundColor: viewMode === 'cheatsheets' ? '#FFBD4C' : 'white', color: 'black', borderColor: viewMode === 'cheatsheets' ? '#666' : '#bbb' }}
                            variant={viewMode === 'cheatsheets' ? 'primary' : 'outline-primary'}
                            onClick={() => handleViewModeChange('cheatsheets')}
                        >
                            <FontAwesomeIcon icon={faRectangleList} className="me-2" />
                            Cheat Sheets
                        </Button>
                        <Button
                            style={{ backgroundColor: viewMode === 'interview' ? '#FFBD4C' : 'white', color: 'black', borderColor: viewMode === 'interview' ? '#666' : '#bbb' }}
                            // variant={viewMode === 'interview' ? 'primary' : 'outline-primary'}
                            onClick={() => handleViewModeChange('interview')}
                        >
                            <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />
                            Interview Questions
                        </Button>
                    </ButtonGroup>
                    <h3 style={{ color: '#007bff' }}>
                        {viewMode === 'cheatsheets' 
                            ? 'Discover over 600+ Cheat Sheets'
                            : 'Practice with Interview Questions'
                        }
                    </h3>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                    <InputGroup className="mb-3 mt-3" style={{ maxWidth: '600px' }}>
                        <InputGroup.Text style={{ backgroundColor: '#f8f9fa', border: '1px #ced4da', borderRadius: '0.375rem' }}>
                            <FontAwesomeIcon icon={faSearch} size="lg" color="#007bff" />
                        </InputGroup.Text>
                        <FormControl 
                            className='topicsForm'
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <Button
                            ref={searchOptionsRef}
                            variant="outline-secondary"
                            onClick={() => setShowSearchOptions(!showSearchOptions)}
                            style={{ border: 'none', backgroundColor: '#fff' }}
                            aria-label="Search Options"
                        >
                            <FontAwesomeIcon icon={faCog} color="#007bff" size="xl" />
                        </Button>
                    </InputGroup>

                    <Overlay
                        target={searchOptionsRef.current}
                        show={showSearchOptions}
                        placement="bottom"
                        rootClose
                        onHide={() => setShowSearchOptions(false)}
                    >
                        <Popover id="search-options-popover">
                            <Popover.Header as="h3">Search Options</Popover.Header>
                            <Popover.Body>
                                <Form onClick={e => e.stopPropagation()}>
                                    <Form.Check
                                        type="checkbox"
                                        id="search-in-title"
                                        label="Search in titles"
                                        checked={searchOptions.searchInTitle}
                                        onChange={() => handleSearchOptionChange('searchInTitle')}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        id="search-in-desc"
                                        label="Search in descriptions"
                                        checked={searchOptions.searchInDesc}
                                        onChange={() => handleSearchOptionChange('searchInDesc')}
                                    />
                                </Form>
                            </Popover.Body>
                        </Popover>
                    </Overlay>
                </div>
            </div>

            {loading ? (
                <div className="spinner-container">
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                </div>
            ) : error ? (
                <div className="alert alert-danger">{error}</div>
            ) : (
                <>
                    <Row xs={2} md={4} lg={6} className="g-2 mt-1 d-flex align-items-stretch">
                        {topics.map((topic) => (
                            <Col key={topic._id}>
                                <Link
                                    to={viewMode === 'cheatsheets' 
                                        ? `/topics/${encodeURIComponent(topic.topic)}`
                                        : `/interview-questions/${encodeURIComponent(topic.topic)}`
                                    }
                                    style={{ textDecoration: 'none' }}
                                >
                                    <div className="topic-card-container">
                                        <ReactCardFlip isFlipped={flip} flipDirection="vertical" >
                                            <Card bg="light" text="dark" className="border-3">
                                                <Card.Body className="border-primary rounded " style={{ backgroundColor: '#D5E2E5', minHeight: '200px' }}>
                                                    <Card.Title className="d-flex justify-content-between align-items-center mb-2">
                                                    {/* { viewMode === 'cheatsheets' &&  */}
                                                        <>
                                                        <div className="title-text" style={{ fontSize: 'clamp(1.1rem, 1.5vw, 1.2rem)', color: '#005190' }}>
                                                            {topic.topic} 
                                                        </div>
                                                        {/* <FontAwesomeIcon 
                                                            icon={faBook}
                                                            className="ms-2"
                                                            style={{ color: '#007bff' }}
                                                        /> */}
                                                        </>
                                                    {/* } */}
                                                    </Card.Title>
                                                    <Card.Text className={expanded[topic._id] ? 'expanded' : ''}>
                                                        {/* { viewMode === 'cheatsheets' &&  */}
                                                            <>
                                                            {topic.description?.length > 75
                                                                ? expanded[topic._id]
                                                                    ? topic.description
                                                                    : `${topic.description.substring(0, 75)}...`
                                                                : topic.description}

                                                            {topic.description?.length > 75 && (
                                                                <Button
                                                                    variant="link"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        setExpanded(prev => ({
                                                                        ...prev,
                                                                        [topic._id]: !prev[topic._id]
                                                                    }));
                                                                    }}
                                                                    style={{ 
                                                                        padding: '0',
                                                                        fontSize: '0.7rem',
                                                                        fontWeight: '500',
                                                                        marginLeft: '5px'
                                                                    }}
                                                                >
                                                                    {expanded[topic._id] ? 'less' : 'more'}
                                                                </Button>
                                                            )}
                                                            </>
                                                        {/* } */}
                                                    </Card.Text>                                                        
                                                </Card.Body>
                                            </Card>
                                            <Card bg="light" text="dark" className="border-3">
                                                <Card.Body className="border-primary rounded " style={{ backgroundColor: '#D9E0DF', minHeight: '200px' }}>
                                                    <Card.Title className="d-flex justify-content-between align-items-center mb-2">
                                                        {/* { viewMode === 'interview' &&  */}
                                                            <>
                                                            <div className="title-text" style={{ fontSize: 'clamp(1.1rem, 1.5vw, 1.2rem)', color: '#005190' }}>
                                                                {topic.topic} ({topic?.beginnerCount + topic?.intermediateCount + topic?.advancedCount})
                                                            </div>
                                                            {/* <FontAwesomeIcon 
                                                                icon={faQuestionCircle}
                                                                className="ms-2"
                                                                style={{ color: '#007bff' }}
                                                            /> */}
                                                            </>
                                                        {/* } */}
                                                    </Card.Title>
                                                    <Card.Text className={expanded[topic._id] ? 'expanded' : ''}>
                                                        {/* { viewMode === 'interview' &&  */}
                                                            <>
                                                                <div className="d-flex flex-column mt-3">
                                                                    <div className="mb-2">
                                                                        Beginner: {topic.beginnerCount || 0}
                                                                    </div>
                                                                    <div className="mb-2">
                                                                        Intermediate: {topic.intermediateCount || 0}
                                                                    </div>
                                                                    <div>
                                                                        Advanced: {topic.advancedCount || 0}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        {/* } */}
                                                    </Card.Text>                                                        
                                                </Card.Body>
                                            </Card>
                                        </ReactCardFlip>
                                    </div>
                                </Link>
                            </Col>
                        ))}
                    </Row>

                    <div style={{ 
                        position: 'sticky',
                        bottom: '0px',
                        backgroundColor: 'white', 
                        zIndex: 1000,
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        borderTop: '1px solid #eee'
                    }}>
                        <div className="text-center">
                            <p className="text-muted mb-2">
                                Page {paginationText}
                                {/* {pagination.currentPage} of {pagination.totalPages} 
                                ({pagination.totalTopics} total topics) */}
                            </p>
                            <div className="d-flex justify-content-center gap-2">
                                <Button
                                    disabled={!pagination.hasPrevPage}
                                    onClick={() => handlePageChange(pagination.currentPage - 1)}
                                    style={{ padding: '5px 15px', fontSize: '1rem', backgroundColor: '#00518F', border: 'none', width: '120px'}}
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} size="sm" /> Previous
                                </Button>
                                <Button
                                    disabled={!pagination.hasNextPage}
                                    onClick={() => handlePageChange(pagination.currentPage + 1)}
                                    style={{ padding: '5px 15px', fontSize: '1rem', backgroundColor: '#00518F', border: 'none', width: '120px'}}
                                >
                                    Next <FontAwesomeIcon icon={faArrowRight} size="sm" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className="mt-4">
                <div style={{ fontSize: '10px', color: 'grey', marginBottom: '5px' }}>advertisement</div>
                <div id="adsense-container" style={{ minHeight: '100px', backgroundColor: '#f8f9fa', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* AdSense code will be inserted here by the script */}
                </div>
            </div>
        </div>
    );
};

export default Topics;
