const API_KEY = process.env.REACT_APP_API_KEY;
const BASE_URL = process.env.REACT_APP_BACKEND_SERVER_URL;

const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${API_KEY}`
};

// Get or create user
export const getOrCreateUser = async (userData) => {
    try {
        const response = await fetch(`${BASE_URL}/api/users/auth`, {
            method: 'POST',
            headers,
            body: JSON.stringify(userData)
        });

        if (!response.ok) {
            throw new Error('Failed to authenticate user');
        }

        return await response.json();
    } catch (error) {
        console.error('Error in getOrCreateUser:', error);
        throw error;
    }
};

// Get user profile
export const getUserProfile = async (userId) => {
    try {
        const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
            method: 'GET',
            headers
        });

        if (!response.ok) {
            throw new Error('Failed to get user profile');
        }

        return await response.json();
    } catch (error) {
        console.error('Error in getUserProfile:', error);
        throw error;
    }
};

// Add to history
export const addToHistory = async (userId, type, item) => {
    try {
        const response = await fetch(`${BASE_URL}/api/users/${userId}/history`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ type, item })
        });

        if (!response.ok) {
            throw new Error('Failed to add to history');
        }

        return await response.json();
    } catch (error) {
        console.error('Error in addToHistory:', error);
        throw error;
    }
};

// Toggle bookmark
export const toggleBookmark = async (userId, type, item) => {
    try {
        const response = await fetch(`${BASE_URL}/api/users/${userId}/bookmarks`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ type, item })
        });

        if (!response.ok) {
            throw new Error('Failed to toggle bookmark');
        }

        return await response.json();
    } catch (error) {
        console.error('Error in toggleBookmark:', error);
        throw error;
    }
};

// Check if item is bookmarked
export const isBookmarked = async (userId, type, itemId) => {
    try {
        const response = await fetch(`${BASE_URL}/api/users/${userId}/bookmarks?type=${type}&itemId=${itemId}`, {
            method: 'GET',
            headers
        });

        if (!response.ok) {
            throw new Error('Failed to check bookmark status');
        }

        return await response.json();
    } catch (error) {
        console.error('Error in isBookmarked:', error);
        throw error;
    }
};

// Update last logout
export const updateLastLogout = async (userId) => {
    try {
        const response = await fetch(`${BASE_URL}/api/users/${userId}/logout`, {
            method: 'POST',
            headers
        });

        if (!response.ok) {
            throw new Error('Failed to update last logout');
        }

        return await response.json();
    } catch (error) {
        console.error('Error in updateLastLogout:', error);
        throw error;
    }
};
