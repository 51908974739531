import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHome,
  faQuestionCircle, 
  faUser,
  faSignOutAlt,
  faRectangleList,
  faFileContract,
  faShieldAlt
} from '@fortawesome/free-solid-svg-icons';
import './Navigation.css';
import logo5 from './images/newlogo.png';

const Navigation = () => {
  const location = useLocation();
  const { currentUser, logout } = useAuth();
  const [expanded, setExpanded] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (expanded && navRef.current && !navRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [expanded]);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  const handleNavClick = () => {
    setExpanded(false);
  };

  return (
    <Navbar ref={navRef} expand="lg" className="navbar-custom" sticky="top" expanded={expanded} onToggle={setExpanded}>
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={handleNavClick}>
        <Link to="/" className="home-link">
            <img src={logo5} className="App-logo" alt="logo" style={{ height: '45px' }} />
          </Link>
          {/* CheatSheet++ */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link 
              as={Link} 
              to="/" 
              active={location.pathname === '/'} 
              onClick={handleNavClick}
            >
              <FontAwesomeIcon icon={faHome} className="me-2" />
              Home
            </Nav.Link>
            <Nav.Link 
              as={Link} 
              to="/topics" 
              active={location.pathname === '/topics'} 
              onClick={handleNavClick}
            >
              <FontAwesomeIcon icon={faRectangleList} className="me-2" />
              Topics
            </Nav.Link>
          </Nav>
          <Nav>
          <Nav.Link 
            as={Link} 
            to="/terms-of-service" 
            active={location.pathname === '/terms'} 
            onClick={handleNavClick}
          >
            <FontAwesomeIcon icon={faFileContract} className="me-2" />
            Terms of Service
          </Nav.Link>
          <Nav.Link 
            as={Link} 
            to="/privacy-policy" 
            active={location.pathname === '/privacy'} 
            onClick={handleNavClick}
          >
            <FontAwesomeIcon icon={faShieldAlt} className="me-2" />
            Privacy Policy
          </Nav.Link>
            {currentUser ? (
              <div className="d-flex align-items-center">
                <Link 
                  to="/profile" 
                  className="me-3 text-light text-decoration-none"
                  onClick={handleNavClick}
                >
                  <FontAwesomeIcon icon={faUser} className="me-2" />
                  {currentUser.displayName || currentUser.email}
                </Link>
                <Button 
                  variant="outline-light" 
                  size="sm"
                  onClick={() => {
                    handleLogout();
                    handleNavClick();
                  }}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                  Logout
                </Button>
              </div>
            ) : (
              <Nav.Link 
                as={Link} 
                to="/login"
                className="btn btn-outline-light btn-sm"
                onClick={handleNavClick}
              >
                <FontAwesomeIcon icon={faUser} className="me-2" />
                Login
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
