import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPlay, faCode, faLightbulb, faRectangleList, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

import TechGraph from '../../components/TechGraph';
import cheatsheetImage from '../../images/cheatsheet.png';
import tutorialImage from '../../images/tutorial.png';
import interviewImage from '../../images/interview-questions.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/Home.css';

const HomePage = () => {
    return (
        <div className="home-container">
            <Helmet>
                <title>Cheatsheet++ | 600+ Software Development Cheat Sheets & Tutorials</title>
                <meta name="description" content="Cheatsheet++ offers a vast collection of free software development cheat sheets, tutorials, and historical context to enhance your learning. Join now and start improving your coding skills!" />
                <meta name="keywords" content="cheat sheets, software development, coding tutorials, programming, learning, developer resources, free educational content" />
                <meta property="og:title" content="Cheatsheet++ | Free Software Development Cheat Sheets & Tutorials" />
                <meta property="og:description" content="Discover a comprehensive collection of cheat sheets and tutorials for software developers. Enhance your coding skills with Cheatsheet++." />
                <meta property="og:image" content="/og-image.jpg" />
                <meta property="og:url" content="https://www.cheatsheet-plus-plus.com" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Cheatsheet++ | Free Software Development Cheat Sheets & Tutorials" />
                <meta name="twitter:description" content="Cheatsheet++ offers a vast collection of free software development cheat sheets, tutorials, and historical context to enhance your learning." />
                <meta name="twitter:image" content="/twitter-image.jpg" />

                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6060537356298204"
                    crossOrigin="anonymous"></script>
            </Helmet>
            
            {/* Hero Section */}
            <div className="hero">
                <h1>Master Software Development with Cheatsheet++</h1>
                <p>Access over 600 cheat sheets and 18,000 tutorials. Enhance your software development skills with our free and regularly updated resources.</p>
                <Link to="/topics">
                    <button className="btn  btn-lg" aria-label="Start Learning Now" style={{ fontSize: '1rem', borderColor: '#444', backgroundColor: '#FF6800', color: 'white', fontWeight: 'bold' }}>
                        Get Started – Access 600+ Resources
                        <FontAwesomeIcon icon={faPlay} style={{ marginLeft: '10px' }} />
                    </button>
                </Link>
            </div>

            <div className="container">
                {/* Features Section */}
                <section className="features-section">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="feature-card">
                                <FontAwesomeIcon icon={faRectangleList} size="2x" className="feature-icon" />
                                <h3>600+ Cheat Sheets</h3>
                                <p>Quick reference guides for all major programming languages and frameworks</p>
                                <img src={cheatsheetImage} alt="Cheat Sheets" className="feature-image" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-card">
                                <FontAwesomeIcon icon={faCode} size="2x" className="feature-icon" />
                                <h3>18,000+ Tutorials</h3>
                                <p>In-depth tutorials to master programming concepts</p>
                                <img src={tutorialImage} alt="Tutorials" className="feature-image" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-card">
                                <FontAwesomeIcon icon={faLightbulb} size="2x" className="feature-icon" />
                                <h3>Interview Prep</h3>
                                <p>Comprehensive interview preparation resources</p>
                                <img src={interviewImage} alt="Interview Questions" className="feature-image" />
                            </div>
                        </div>
                    </div>
                </section>

                

                {/* Tech Graph Section */}
                <section className="tech-graph-section">
                    {/* <h2>Explore Our Technology Coverage</h2> */}
                    <Link to="/topics">
                        <button className="btn btn-outline btn-lg" aria-label="Start Learning Now" style={{ fontSize: '1rem', borderColor: '#444', marginBottom: '40px', backgroundColor: '#FF6800', color: 'white', fontWeight: 'bold' }}>
                            Explore Our Technology Coverage
                            <FontAwesomeIcon icon={faPlay} style={{ marginLeft: '10px' }} />
                        </button>
                    </Link>
                    <TechGraph />
                </section>

                {/* Benefits Section */}
                <section className="benefits-section">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="card" style={{ border: 'none' }}>
                                <div className="card-body">
                                    <h2>Why Cheat Sheets Are Essential for Developers</h2>
                                    <p>
                                        Cheat sheets provide developers with a quick and powerful way to grasp and retain complex information. 
                                        In a fast-paced industry where new tools and frameworks emerge constantly, having a reliable reference guide is invaluable.
                                    </p>
                                    <p>
                                        At CheatSheet++, we offer interactive, customizable, and up-to-date cheat sheets designed to help you master new technologies 
                                        and enhance problem-solving skills efficiently.
                                    </p>
                                    <Link to="/topics">
                                        <button className="btn btn-primary" aria-label="Start Learning Now" style={{ fontSize: '1rem', borderColor: '#444', backgroundColor: '#FF6800', color: 'white', fontWeight: 'bold' }}>>
                                            Start Learning
                                            <FontAwesomeIcon icon={faPlay} style={{ marginLeft: '10px' }} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="benefits-list-card">
                                <h3>Benefits of Cheat Sheets</h3>
                                <ul>
                                    <li><FontAwesomeIcon icon={faCheck} /> Quick Reference</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Enhanced Retention</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Time Efficiency</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Focus on Key Points</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Improved Organization</li>
                                    <li><FontAwesomeIcon icon={faCheck} /> Active Learning</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Call to Action Section */}
                <section className="cta-section">
                    <div className="card">
                        <div className="card-body text-center">
                            <h2>Ready to Level Up Your Development Skills?</h2>
                            <p>Join thousands of developers who use CheatSheet++ to master new technologies and ace technical interviews.</p>
                            <Link to="/topics">
                                <button className="btn btn-primary btn-lg" style={{ fontSize: '1rem', borderColor: '#444', backgroundColor: '#FF6800', color: 'white', fontWeight: 'bold' }}>>
                                    Access 600+ Resources Now
                                    <FontAwesomeIcon icon={faPlay} style={{ marginLeft: '10px' }} />
                                </button>
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default HomePage;
