import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as regularBookmark } from '@fortawesome/free-regular-svg-icons';
import './BookmarkButton.css';

const BookmarkButton = ({ type, item }) => {
  const { currentUser, isBookmarked, toggleBookmark } = useAuth();
  const [isMarked, setIsMarked] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkBookmarkStatus = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const status = await isBookmarked(type, item.id);
        setIsMarked(status);
      } catch (error) {
        console.error('Error checking bookmark status:', error);
      }
      setLoading(false);
    };

    checkBookmarkStatus();
  }, [type, item.id, isBookmarked, currentUser]);

  const handleToggleBookmark = async (e) => {
    e.preventDefault(); // Prevent navigation if button is inside a link
    if (!currentUser) {
      // TODO: Show login prompt or redirect to login page
      return;
    }

    setLoading(true);
    try {
      const isNowBookmarked = await toggleBookmark(type, item);
      setIsMarked(isNowBookmarked);
    } catch (error) {
      console.error('Error toggling bookmark:', error);
    }
    setLoading(false);
  };

  if (!currentUser) {
    return (
      <button
        className="bookmark-button"
        onClick={handleToggleBookmark}
        title="Login to bookmark"
      >
        <FontAwesomeIcon 
          icon={regularBookmark}
          style={{ opacity: 0.5 }}
          size='2x'
        />
      </button>
    );
  }

  return (
    <button
      className={`bookmark-button ${isMarked ? 'active' : ''}`}
      onClick={handleToggleBookmark}
      disabled={loading}
      title={isMarked ? 'Remove bookmark' : 'Add bookmark'}
    >
      <FontAwesomeIcon 
        icon={isMarked ? solidBookmark : regularBookmark}
        className={loading ? 'fa-pulse' : ''}
        size='2x'
      />
    </button>
  );
};

export default BookmarkButton;
