import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons';
import { useAuth } from '../../contexts/AuthContext';
import '../../styles/Login.css';

export default function SocialAuthButtons() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { loginWithGoogle, loginWithFacebook, loginWithGithub } = useAuth();

  const handleSocialLogin = async (provider) => {
    try {
      setError('');
      setLoading(true);
      
      let result;
      switch (provider) {
        case 'google':
          result = await loginWithGoogle();
          break;
        case 'facebook':
          result = await loginWithFacebook();
          break;
        case 'github':
          result = await loginWithGithub();
          break;
        default:
          throw new Error('Invalid provider');
      }

      // Get the redirect path from location state or default to home
      const from = location.state?.from?.pathname || '/';
      navigate(from);

      // You could show a welcome message for new users
      if (result?.additionalUserInfo?.isNewUser) {
        // Could use a toast notification here
        console.log('Welcome to CheatSheet++!');
      }

    } catch (error) {
      console.error('Login error:', error);
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          setError('Login cancelled. Please try again.');
          break;
        case 'auth/popup-blocked':
          setError('Please enable popups for this site.');
          break;
        case 'auth/account-exists-with-different-credential':
          setError('An account already exists with this email using a different login method.');
          break;
        default:
          setError('Failed to login. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="social-auth-container">
      {error && <div className="alert alert-danger">{error}</div>}
      
      <div className="social-buttons">
        <button
          onClick={() => handleSocialLogin('google')}
          className="social-btn google"
          disabled={loading}
        >
          <FontAwesomeIcon icon={faGoogle} />
          <span>Login with Google</span>
        </button>

        {/* <button
          onClick={() => handleSocialLogin('facebook')}
          className="social-btn facebook"
          disabled={loading}
        >
          <FontAwesomeIcon icon={faFacebook} />
          <span>Login with Facebook</span>
        </button> */}

        <button
          onClick={() => handleSocialLogin('github')}
          className="social-btn github"
          disabled={loading}
        >
          <FontAwesomeIcon icon={faGithub} />
          <span>Login with GitHub</span>
        </button>
      </div>
    </div>
  );
}
