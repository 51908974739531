import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck, faGraduationCap, faHistory, faPrint, faQuestionCircle, faGift, faHandPointRight } from '@fortawesome/free-solid-svg-icons';

import API_KEY from '../config';
import { Helmet } from 'react-helmet';
import AdSense from './AdSense';
import BookmarkButton from './common/BookmarkButton';

import 'react-tooltip/dist/react-tooltip.css';
import '../styles/topicDetailsStyle.css';

const Tutorial = () => {
    const {topic_subtopic} = useParams();

    const [topic, setTopic] = useState("");
    const [subtopic, setSubtopic] = useState("");
    const [tutorialContent, setTutorialContent] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //console.log(`<<<<<<<<<<<<<< Calling getPageData with Sub Topic: ${topic_subtopic}`);
        var p = topic_subtopic.split('_');
        setTopic(p[0]);
        setSubtopic(p[1]);
        //console.log(`>>>>>>>>>>>>>>>>>>>>>>>>> Topic: ${p[0]}, Subtopic: ${p[1]}`);
        fetchTutorial(p[1]); 
    }, [topic_subtopic]);

    useEffect(() => {
        // Select all <pre> tags and check for nested <code> tags
        const preTags = document.querySelectorAll('pre');
        
        preTags.forEach(preTag => {
            const codeTag = preTag.querySelector('code');
            
            preTag.style.backgroundColor = '#494949';  // Example: change background color
            preTag.style.padding = '10px';             // Example: add padding
            preTag.style.color = 'white';

            if (codeTag) {
                // Add inline style to the <code> tag
                codeTag.style.backgroundColor = '#494949';
                codeTag.style.color = 'white';           // Example: change text color
                codeTag.style.fontSize = '1rem';           // Example: change font size
            }
        });
    }, [tutorialContent]);

    const fetchTutorial = async (topic_subtopic) => {
        setLoading(true);
        try {
            //console.log(`getting subtopic tutorial for subtopic: ${topic_subtopic}`);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/tutorials/${encodeURIComponent(topic_subtopic)}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${API_KEY}`
                }
            }); 
            const data = await response.json();
            setTutorialContent((await data.content));
        } catch (error) {
            console.error('Error fetching subtopic tutorial:', error);
        } finally {
            setLoading(false);
        }
    };
      
    const RenderHtmlContent = ({ htmlString }) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);        
        return (
            <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        );
    };
      
    return (
        <div style={styles.panel}>
            <Helmet>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6060537356298204"
                    crossOrigin="anonymous"></script>
            </Helmet>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className="tutorial-container">
                        <div className="content-column">
                            <div style={styles.container}>
                                {/* <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                                <AdSense adClient="ca-pub-6060537356298204" adSlot="2618836852" adFormat="fluid" layoutKey="-fb+5w+4e-db+86" /> */}
                                <div style={{ marginTop: '20px' }}>
                                    <BookmarkButton 
                                        type="tutorial"
                                        item={{
                                            id: topic_subtopic,
                                            title: topic_subtopic.split('_')[1]
                                        }}
                                    />
                                    
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Link to={`/topics/${encodeURIComponent(topic)}`} style={{ display: 'flex', alignItems: 'center' }}>
                                            <FontAwesomeIcon icon={faHandPointRight} size="xl" color="red" />
                                            <span style={{ marginLeft: '10px', marginBottom: '0' }}>Check out the cheat sheet</span>
                                        </Link>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Link to={`/interview-questions/${encodeURIComponent(topic)}`} style={{ display: 'flex', alignItems: 'center' }}>
                                            <FontAwesomeIcon icon={faGift} size="xl" color="red"/>
                                            <span style={{ marginLeft: '10px', marginBottom: '0' }}>Also see our Interview Q&A growing library</span>
                                        </Link>
                                    </div>

                                    <br/>

                                    <RenderHtmlContent htmlString={tutorialContent.replace('<html>', '').replace('</html>', '')} />
                                </div>

                                <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                                <AdSense adClient="ca-pub-6060537356298204" adSlot="5922854719" adFormat="auto" />
                            </div>
                        </div>
                        <div className="ad-column"
                            style={{
                                width: '100%',
                                minWidth: '300px',
                                margin: '20px 0',
                                display: 'block', 
                            }}
                            >
                            <span style={{ fontSize: '10px', color: 'grey' }}>advertisement</span>
                            <AdSense
                                adClient="ca-pub-6060537356298204"
                                adSlot="9422058474"
                                adFormat="autorelaxed"
                                style={{ display: 'block', width: '100%' }} 
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const styles = {
    panel: {
        flex: 1,
        backgroundColor: '#FFF',
        padding: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    container: {
        // marginTop: '30px',
        paddingLeft: '20px',
        paddingRight: '20px',
        // paddingTop: '5px',
        fontFamily: 'Arial, sans-serif'
    }
}

export default Tutorial;
