import React from 'react';
import { useParams } from 'react-router-dom';
import InterviewQuestions from '../InterviewQuestions';
import { Helmet } from 'react-helmet';
import AdSense from '../AdSense';

const InterviewQuestionsPage = () => {
    const { topic } = useParams();

    return (
        <div className="container" style={{ marginTop: '0px' }}>
            <Helmet>
                <title>{`${topic} Interview Questions - Cheatsheet++`}</title>
                <meta
                    name="description"
                    content={`Practice ${topic} interview questions and prepare for your next technical interview with comprehensive answers and explanations.`}
                />
            </Helmet>
            {/* <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
            <AdSense adClient="ca-pub-6060537356298204" adSlot="2618836852" adFormat="fluid" layoutKey="-fb+5w+4e-db+86" /> */}
            <InterviewQuestions topic={topic} />
        </div>
    );
};

export default InterviewQuestionsPage;
