import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import SocialAuthButtons from "../authentication/SocialAuthButtons";
import { Helmet } from 'react-helmet';
import '../../styles/Login.css';

const Login = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // Redirect if user is already logged in
  React.useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  return (
    <div className="login-container">
      <Helmet>
          <title>Login - Cheatsheet++ | Access Your Personal Learning Hub</title>
          <meta
              name="description"
              content="Log in to Cheatsheet++ to access personalized cheat sheets, tutorials, and your saved resources. Quick and easy sign-in options available."
          />
          <meta name="robots" content="index, follow" />
          
          {/* Open Graph Tags */}
          <meta property="og:title" content="Login - Cheatsheet++" />
          <meta
              property="og:description"
              content="Access your personal learning hub with Cheatsheet++. Log in to explore cheat sheets, tutorials, and saved resources."
          />
          <meta property="og:image" content="https://example.com/path-to-preview-image.jpg" />
          <meta property="og:url" content="https://cheatsheet-plus-plus.com/login" />
          <meta property="og:type" content="website" />

          {/* Twitter Card Tags */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="Login - Cheatsheet++" />
          <meta
              name="twitter:description"
              content="Log in to explore your personal learning hub with Cheatsheet++."
          />
          <meta name="twitter:image" content="https://example.com/path-to-preview-image.jpg" />

          {/* Canonical URL */}
          <link rel="canonical" href="https://cheatsheet-plus-plus.com/login" />
      </Helmet>
      <header>
          <h1>Login</h1>
      </header>

      {/* <h2>Welcome to CheatSheet++</h2> */}
      <p className="text-muted">
      <h5><br></br>Select your favorite login method to get CheatSheet++ premium access.</h5>
      </p>
      
      <SocialAuthButtons />
      
      <div className="login-footer">
        <p className="text-muted text-center mt-4">
          By continuing, you agree to our{' '}
          <a href="/terms-of-service">Terms of Service</a> and{' '}
          <a href="/privacy-policy">Privacy Policy</a>
        </p>
      </div>
    </div>
  );
};

export default Login;
