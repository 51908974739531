import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  onAuthStateChanged,
  signOut
} from 'firebase/auth';
import { auth } from '../firebase';
import * as userService from '../services/userService';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  async function handleUserProfile(user, provider) {
    // console.log('calling handleUserProfile');
    try {
      const userData = {
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        socialId: user.uid,
        provider: provider
      };

      const dbUser = await userService.getOrCreateUser(userData);
      // console.log(dbUser);
      setUserData(dbUser);
      return dbUser;
    } catch (error) {
      console.error('Error handling user profile:', error);
      throw error;
    }
  }

  async function loginWithGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      await handleUserProfile(result.user, 'google');
      return result;
    } catch (error) {
      console.error('Google login error:', error);
      throw error;
    }
  }

  async function loginWithFacebook() {
    try {
      const provider = new FacebookAuthProvider();
      const result = await signInWithPopup(auth, provider);
      await handleUserProfile(result.user, 'facebook');
      return result;
    } catch (error) {
      console.error('Facebook login error:', error);
      throw error;
    }
  }

  async function loginWithGithub() {
    try {
      const provider = new GithubAuthProvider();
      const result = await signInWithPopup(auth, provider);
      await handleUserProfile(result.user, 'github');
      return result;
    } catch (error) {
      console.error('Github login error:', error);
      throw error;
    }
  }

  async function logout() {
    if (currentUser && userData) {
      await userService.updateLastLogout(userData._id);
    }
    setUserData(null);
    return signOut(auth);
  }

  async function getUserProfile() {
    if (!userData) return null;
    try {
      const profile = await userService.getUserProfile(userData._id);
      setUserData(profile);
      return profile;
    } catch (error) {
      console.error('Error getting user profile:', error);
      return null;
    }
  }

  async function addToHistory(type, item) {
    if (!userData) return;
    try {
      await userService.addToHistory(userData._id, type, item);
    } catch (error) {
      console.error('Error adding to history:', error);
    }
  }

  async function toggleBookmark(type, item) {
    if (!userData) return false;
    try {
      const { isBookmarked } = await userService.toggleBookmark(userData._id, type, item);
      return isBookmarked;
    } catch (error) {
      console.error('Error toggling bookmark:', error);
      return false;
    }
  }

  async function isBookmarked(type, itemId) {
    if (!userData) return false;
    try {
      const { isBookmarked } = await userService.isBookmarked(userData._id, type, itemId);
      return isBookmarked;
    } catch (error) {
      console.error('Error checking bookmark status:', error);
      return false;
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // When user signs in, get or create their MongoDB profile
          const dbUser = await handleUserProfile(user, user.providerData[0]?.providerId.replace('.com', '') || 'google');
          setUserData(dbUser);
        } catch (error) {
          console.error('Error loading user data:', error);
        }
      } else {
        setUserData(null);
      }
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userData,
    loginWithGoogle,
    loginWithFacebook,
    loginWithGithub,
    logout,
    getUserProfile,
    addToHistory,
    toggleBookmark,
    isBookmarked
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
